<template>
  <div>
    <ds-header :title="title"></ds-header>
    <div>
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="keyWordGroupName" label="关键词组名称" prop="keyWordGroupName">
          <a-input
            :disabled="disable"
            v-model="form.keyWordGroupName"
            placeholder="请输入关键词组名称"
            class="inputClass"
            @blur="
          () => {
            $refs.keyWordGroupName.onFieldBlur();
          }
        "
          />
        </a-form-model-item>
        <a-form-model-item ref="projectId" label="所属项目" prop="projectId">
          <a-select
            :disabled="disable"
            placeholder="请选择所属项目"
            v-model="form.projectId"
            class="inputClass"
          >
            <a-select-option v-for="item in projectList" :key="item.id">
              {{ item.project_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="状态" :required="true">
          <a-radio-group :disabled="disable" v-model="form.status">
            <a-radio :value="1">
              启用
            </a-radio>
            <a-radio :value="0">
              禁用
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="关键词组" :required="true">
          <div style="margin-top: 20px">
            <a-button @click="addRow"  type="link" v-show="!disable">添加</a-button>
            <a-table style="width: 500px;margin-top: 0px" :pagination="false" :columns="columns" :data-source="data">
            <span slot="content"  slot-scope="text, record">
              <div v-html="text"></div>
            </span>
              <span slot="imageUrl"  slot-scope="text, record">
              <img width="60px" height="60px" :src="text" alt=""/>
            </span>
              <span slot="operation"  slot-scope="text, record,index">
              <a-button type="link" @click="deleteKeyWord(index)">删除</a-button>
            </span>
            </a-table>
          </div>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button v-show="!disable" type="primary" @click="onSubmit">
            保存
          </a-button>
          <a-button style="margin-left: 10px;" @click="back">
            {{ disable ? '返回' : '取消' }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-modal
      title="添加关键词"
      :visible="visible"
      :footer="null"
      :width="1000"
      :maskClosable="false"
      :closable="false"
      :destroyOnClose='true'
    >
      <a-form-model ref="keyWordForm" :model="keyWordForm" :rules="keyWordRules" :label-col="keyWordLabelCol" :wrapper-col="keyWordWrapperCol">
        <a-form-model-item ref="keyWord" label="关键词名称" prop="keyWord">
          <a-input
            v-model="keyWordForm.keyWord"
            @blur="
                () => {
                  $refs.keyWord.onFieldBlur();
                }
              "
          />
        </a-form-model-item>
        <a-form-model-item ref="content" label="详情介绍" prop="content">
          <div class="content">
            <div id="editer"></div>
          </div>
        </a-form-model-item>
        <a-form-model-item ref="imageUrl" label="关键词图片" prop="imageUrl">
          <a-upload
            name="file"
            list-type="picture-card"
            :action="IMG_API + '/oss/files'"
            class="avatar-uploader"
            :file-list="imageList"
            @change="handleChangeImg"
            :showUploadList="{ showPreviewIcon: false, showRemoveIcon: true }"
            :before-upload="imgBeforeUpload"
          >
            <div v-if="imageList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
          <p class="tip"><span style="color: red;margin-right: 5px">*</span>请上传尺寸2:3，格式为PNG、JPG的图片</p>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 5 }">
          <a-button type="primary" @click="addKeyWordRow">
            保存
          </a-button>
          <a-button style="margin-left: 10px;" @click="cancel">
            取消
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

  </div>
</template>

<script>
import { getKeyWordDetailById, updateKeyWordById } from '@/api/yearEndActivities'
import { projectName } from '@/api/modularManagement'
import { IMG_API } from "@/config/index";
import wangEditor from "wangeditor";
const data = [];
export default {
  name: "view",
  data() {
    return {
      disable: false,
      visible: false,
      IMG_API: IMG_API,
      imageList: [],
      // 关键词
      keyWordForm: {
        keywordGroupId: '',
        keyWord: '',
        content: '',
        imageUrl: ''
      },
      data,
      title: '',
      columns:[
        {
          dataIndex: "keyWord",
          key: "keyWord",
          title: "关键词名称"
        },
        {
          title: "详情介绍",
          dataIndex: "content",
          key: "content",
          scopedSlots: { customRender: 'content' },
        },
        {
          title: "关键词图片",
          key: "imageUrl",
          dataIndex: "imageUrl",
          scopedSlots: { customRender: 'imageUrl' },
        },
        {
          title: "操作",
          scopedSlots: { customRender: 'operation' },
        }
      ],
      projectList:[],
      labelCol: { span: 4 },
      wrapperCol: { span: 6 },
      keyWordLabelCol: { span: 4 },
      keyWordWrapperCol: { span: 16 },
      other: '',
      form: {
        id: '',
        keyWordGroupName: '',
        projectId: undefined,
        status: 1,
        keywords:[],
        projectName: '',
      },
      rules: {
        keyWordGroupName: [
          { required: true, message: '请输入关键词组名称！', trigger: 'blur' },
        ],
        projectId: [
          { required: true, message: '请选择所属项目！', trigger: 'blur' },
        ],
      },
      keyWordRules: {
        keyWord: [
          {required: true, message: '请输入关键词名称！', trigger: 'blur'},
          {max: 6, message: '关键词名称不能超过6个字符！', trigger: 'blur'}
        ],
        content: [
          {required: true, message: '请输入详情介绍！', trigger: 'blur'}
        ],
        imageUrl:[
          {required: true, message: '请上传关键词图片！', trigger: 'change'}
        ]
      },
    };
  },
  created() {
    this.selectProjectList();
    this.form.id = this.$route.query.id
    if (this.$route.query.type === 'view') {
      this.disable = true
      this.columns.splice(3,1)
    }
    this.title = this.$route.query.type === 'view' ? '查看' : '编辑'
    if (this.form.id) {
      this.findById()
    }
  },
  methods: {
    findById() {
      getKeyWordDetailById(this.form.id).then(res => {
        if (res.code === '200') {
          const { data } = res
          this.form.keyWordGroupName = data.keyWordGroupName
          this.form.projectId = data.projectId
          this.form.status = data.status
          this.data = data.keywords
        }
      })
    },
    async selectProjectList(){
      const res = await projectName();
      if (res.code === '200') {
        this.projectList = res.data.items;
      }
    },
    back() {
      this.$router.back()
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.data.length > 0) {
            this.form.keywords =  this.data
          } else {
            this.$message.error("关键词组不能为空！")
            return false;
          }
          this.form.projectName = this.projectList.find(item => item.id === this.form.projectId).project_name
          updateKeyWordById(this.form).then(res => {
            if (res.code === '200') {
              this.$message.success("操作成功！")
              this.$router.back()
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    deleteKeyWord(index) {
      this.data.splice(index, 1)
    },
    // 上传图片
    handleChangeImg({ fileList }) {
      this.imageList = fileList;
      if(this.imageList.length > 0 && fileList[0].status === 'done') {
        this.keyWordForm.imageUrl = this.imageList[0].response.redirect_uri
      } else {
        this.keyWordForm.imageUrl = ''
      }
    },
    //上传文件之前校验图片大小
    imgBeforeUpload(file) {
      const that = this
      let index = file.name.lastIndexOf(".");
      let type = file.name.substring(index + 1, file.name.length);
      const isJPG = type === "png" || type === "jpg";
      if (!isJPG) {
        this.$message.error("上传图片只能是jpg、png格式!");
        return new Promise(function (resolve, reject) {return reject});
      }
      if (file.size/1024 > 500) {
        this.$message.error("上传图片不能大于500KB!");
        return new Promise(function (resolve, reject) {return reject});
      }
      const isSize = new Promise(function (resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          const proportion = that.reductionTo(img.width, img.height);
          const valid = proportion[0] === 2 && proportion[1] === 3
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          that.$message.error("图片不规范，请按要求上传");
          return reject();
        }
      );
      return isJPG && isSize;
    },
    // 取消
    cancel() {
      this.visible = false
    },
    // 关键词添加
    addKeyWordRow() {
      this.$refs.keyWordForm.validate(valid => {
        if (valid) {
          this.data.push(this.keyWordForm)
          this.resetKeyWordForm()
          this.visible = false
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置form表单
    resetKeyWordForm() {
      this.keyWordForm = {
        keywordGroupId: '',
        keyWord: '',
        content: '',
        imageUrl: ''
      }
      this.imageList = []
    },
    addRow() {
      this.resetKeyWordForm()
      this.visible = true
      this.$nextTick(() => {
        this.createEditor()
      })
    },
    createEditor() {
      const _this = this;
      const editor = new wangEditor(`#editer`);
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.keyWordForm.content = newHtml;
      };
      editor.config.menus = [
        "fontName",
        "fontSize",
        "foreColor",
        "backColor",
        "underline",
        "italic",
        "bold",
        "justify",
        "splitLine",
        "undo",
        "redo",
        "list",
        "colors"
      ];
      editor.config.colors = [
        '#3fabff',
        '#9d9452',
        '#76849e',
        '#e3edff',
        '#3c3e02',
        '#011b7c',
        '#3e1602',
        '#ffffff',
        '#000000',
      ]
      editor.config.uploadVideoServer = this.IMG_API + "/oss/files";
      editor.config.uploadImgServer = this.IMG_API + "/oss/files";
      editor.config.uploadImgShowBase64 = false;
      editor.config.showLinkImg = false;
      editor.config.showLinkVideo = false;
      editor.config.uploadVideoMaxSize = 5 * 1024 * 1024;
      editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
      editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif"];
      editor.config.uploadImgMaxLength = 1; // 一次最多上传 1 个图
      // editor.config.uploadVideoAccept = ['mp4']
      editor.config.uploadFileName = "file";
      editor.config.uploadVideoName = "file";
      editor.config.uploadVideoTimeout = 60000;
      editor.config.uploadImgTimeout = 60000;
      editor.config.uploadVideoHooks = {
        // 上传视频之前
        before: function (xhr, editor, files) {
          console.log(xhr);
          console.log(editor);
          console.log(files);
        },
        // 视频上传并返回了结果，视频插入已成功
        success: function (xhr) {
          // this.$message.success('上传视频成功')
        },
        // 视频上传并返回了结果，但视频插入时出错了
        fail: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频出错，一般为 http 请求的错误
        error: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频超时
        timeout: function (xhr) {
          _this.$message.error("上传请求超时");
        },
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function (insertVideoFn, result) {
          let videoHTML =
            '&nbsp;<video poster="' +
            result.redirect_uri +
            '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' +
            result.redirect_uri +
            '" controls style="max-width:100%"></video>&nbsp;';
          editor.cmd.do("insertHTML", videoHTML);
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          // insertVideoFn(result.redirect_uri)
        },
      };
      editor.config.uploadImgHooks = {
        // 上传视频之前
        before: function (xhr) {},
        // 视频上传并返回了结果，视频插入已成功
        success: function (xhr) {
          // this.$message.success('上传视频成功')
        },
        // 视频上传并返回了结果，但视频插入时出错了
        fail: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频出错，一般为 http 请求的错误
        error: function (xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频超时
        timeout: function (xhr) {
          _this.$message.error("上传请求超时");
        },
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function (insertVideoFn, result) {
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          insertVideoFn(result.redirect_uri);
        },
      };
      editor.config.customAlert = function (s, t) {
        switch (t) {
          case "success":
            _this.$message.success(s);
            break;
          case "info":
            _this.$message.info(s);
            break;
          case "warning":
            _this.$message.warning(s);
            break;
          case "error":
            _this.$message.error(s);
            break;
          default:
            _this.$message.info(s);
            break;
        }
      };
      editor.config.placeholder = "请输入";
      // 创建编辑器
      editor.create();
      this.editor = editor;
      setTimeout(() => {
        this.editor.txt.html(this.keyWordForm.content)
      },500);
      // 查看的时候禁用富文本
      // if (this.$route.query.type === "view") {
      //   this.editor.disable();
      // } else {
      //   this.editor.enable();
      // }
    },
    //计算图片比例
    reductionTo(m, n) {
      let arr = [];
      let a = m;
      let b = n;
      (a >= b) ? (a = m, b = n) : (a = n, b = m);
      if (m != 1 && n != 1) {
        for (let i = b; i >= 2; i--) {
          if (m % i == 0 && n % i == 0) {
            m = m / i;
            n = n / i;
          }
        }
      }
      arr[0] = m;
      arr[1] = n;
      return arr;
    },
  }
};
</script>

<style scoped>
.main li {
  line-height: 40px;
  list-style-type: none;
}
.backClass {
  padding-left: 200px;
  padding-top: 20px;
}
.inputClass {
  width: 300px;
}
</style>
